
export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

const initialState = {
    loading: false,
    error: null,
    orderId: null,
    receiptNo: null
};

const createorderReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_ORDER_REQUEST:
            return { ...state, loading: true, error: null };
        case CREATE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                orderId: action.payload.order_id,
                receiptNo: action.payload.receipt_no,
                error: null
            };
        case CREATE_ORDER_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default createorderReducer;