import React from 'react';
import { Link } from 'react-router-dom';
import Order_Table from '../Tables/Orders_Table';


function Orders() {
  return (
    <>
      {/* <div className="px-20 flex gap-4 flex-wrap w-[100%]" style={{ maxWidth: "100vw", margin: "0 auto" }}> */}
      <div className='bg-[#EEEEEE] mt-5'>
        <Order_Table/>
      </div>
    </>
  )
}

export default Orders

