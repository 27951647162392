import React, { useState, forwardRef } from "react";
import { barcode_icon, search_icon } from "../../../assets";
import { useTranslation } from "react-i18next";

const ProductSearchBar = forwardRef (({ onSearch,value }, ref) => {
  const { t,i18n } = useTranslation();
  const [search, setsearch] = useState("");
  return (
    <div className="relative flex ">
      <span className="absolute inset-y-0 mx-2 start-0 flex items-center ps-3">
        <img src={search_icon} alt="search_icon" />
      </span>
      <input
        // value={value}
        ref={ref}
        onChange={onSearch}
        type="text"
        placeholder={t("Search product")}
        className="border p-2 ps-14 rounded-lg focus:outline-none w-[100%]"
      />

      <span className="absolute inset-y-0 end-0 mx-2 flex items-center pe-2 mt-0.5 h-10 w-10">
        <img src={barcode_icon} alt="right_icon" />
      </span>
      {/* <span className="flex items-center pointer-events-none">
                <img src={barcode_icon} alt="barcode_icon" />
            </span> */}
    </div>
  );
});

export default ProductSearchBar;
