import { 
    FETCH_ORDER_RECEIPT_REQUEST, 
    FETCH_ORDER_RECEIPT_SUCCESS, 
    FETCH_ORDER_RECEIPT_FAILURE 
} from '../Action/orderReceiptAction';

const initialState = {
    loading: false,
    order: null,
    error: ''
};

const orderReceiptReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ORDER_RECEIPT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_ORDER_RECEIPT_SUCCESS:
            return {
                loading: false,
                order: action.payload,
                error: ''
            };
        case FETCH_ORDER_RECEIPT_FAILURE:
            return {
                loading: false,
                order: null,
                error: action.payload
            };
        default:
            return state;
    }
};

export default orderReceiptReducer;
