import { ADD_RETURN_PRODUCTS, CLEAR_RETURN_PRODUCTS } from "../Action/returnproductAction";

const initialState = {
  products: [],
};

export const returnProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_RETURN_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case CLEAR_RETURN_PRODUCTS:
      return {
        ...state,
        products: [],
      };
    default:
      return state;
  }
};


export default returnProductReducer;
    