const initialState = {
  OpenCashModal: true,
};

const sessionidentifierReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PREVIOUS_SESSION_SUCCESS':
      return { ...state, OpenCashModal: action.payload };
    default:
      return state;
  }
};

export default sessionidentifierReducer;
