// import { configureStore } from '@reduxjs/toolkit';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
// import rootReducer from './Reducers/Reducers';



// // Configuration object for redux-persist
// const persistConfig = {
//   key: 'root',
//   storage,
// };

// // Create a persisted reducer
// const persistedReducer = persistReducer(persistConfig, rootReducer);

// // Configure store with persisted reducer
// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware({
//     serializableCheck: {
//       ignoredActions: ['persist/PERSIST'],
//     },
//   }),
// });

// // Create a persistor
// const persistor = persistStore(store);

// export default  { store, persistor };


// import { configureStore } from '@reduxjs/toolkit';
// import rootReducer from './Reducers/Reducers';

// const store = configureStore({
//   reducer: rootReducer,
// });

// export default store;



import { createStore,applyMiddleware } from "redux";
import { persistStore,persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from './Reducers/Reducers';
import { default as thunk } from "redux-thunk";


const persistConfig={
  key:'root',
  storage
}
const persistedReducer=persistReducer(persistConfig,rootReducer);

const store=createStore(persistedReducer,applyMiddleware(thunk));

const persistor=persistStore(store)

export {store,persistor};

