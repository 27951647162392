import React, { useEffect, useRef, useState } from "react";
import { CashInOut } from "../../Api";
import { URLS } from "../../API/API endpoints";
import axiosInstance from "../../AxiosInstance";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function InputField({
  label,
  placeholder,
  value,
  onChange,
  type = "text",
  onEnterPress,
  inputRef,
}) {
  const { t, i18n } = useTranslation();
  // const inputRef = React.createRef();

  // React.useEffect(() => {
  //   inputRef.current.focus();
  // }, []);

  const handleKeyPress = (e) => { 
    if (e.key === "Enter" && typeof onEnterPress === 'function') { 
      e.preventDefault();
      onEnterPress(); 
    } 
  };

  return (
    <>
      <div className="mt-9 font-medium max-md:max-w-full">{t(label)}</div>
      <div
        className={`flex border border-solid rounded-xl overflow-hidden justify-center items-start ${
          i18n.language === "ar" ? "flex-row-reverse" : ""
        }`}
      >
        {label === "Amount" && (
          <span className="pl-3 py-3 bg-white  text-black max-md:pe-3">
            EGP
          </span>
        )}
        <input
          ref={inputRef}
          type={type}
          placeholder={t(placeholder)}
          value={value}
          style={{ direction: "ltr" }}
          onChange={(e) => onChange(e.target.value)}
          onKeyPress={handleKeyPress}
          className="p-3 bg-white rounded-xl text-left border-0 border-neutral-400 border-opacity-50 text-black max-md:pr-5 max-md:max-w-full w-full focus:outline-none focus:border-2px"
        />
      </div>
    </>
  );  
}

function CashIn_Modal({ onClose, title, handleSubmit, cashDifference }) {
  const { t, i18n } = useTranslation();
  const [amount, setAmount] = useState("");
  const [reason, setReason] = useState("");
  const userData = useSelector((state) => state?.login?.user?.result);
  const lastCallTime = React.useRef(0);
  const amountInputRef = useRef(null); 
  const [hasConfirmed, setIshasConfirmed] = useState(false);
  const [isTransactionInProgress, setIsTransactionInProgress] = useState(false);



  useEffect(() => {
    if (amountInputRef.current) {
      amountInputRef.current.focus();
    }
  }, []);

  let timeoutId = null;


  const handleConfirm = async () => {

    if (isTransactionInProgress) return; // prevent multiple transactions
    setIsTransactionInProgress(true);


    try {
      const delay = 500; 
      if (Date.now() - lastCallTime.current < delay) {
        return;
      }
      lastCallTime.current = Date.now();
  
      let value;
      if (title === "Cash In") {
        value = parseFloat(amount);
      } else {
        value = -parseFloat(amount);
      }
  
      if (isNaN(value)) {
        toast.error("Amount must be a valid number");
        return;
      }

      // if (title === "Cash Out" && value > -parseFloat(cashDifference)) {
      //   toast.error(t("Not enough cash"));
      //   return;
      // }
      if (title === "Cash Out" && parseFloat(amount) > -cashDifference) {
        // toast.error(t("Not enough cash"));
        return;
      }

      // console.log("Cash Diff", cashDifference);
  
      const response = await axiosInstance.post(URLS.CASH_IN_OUT, {
        session_id: userData?.session_id,
        type: title === "Cash In" ? "cash_in" : "cash_out",
        amount: value,
        reason: reason,
      });
      if (response?.data?.result?.status_code === 200) {
        handleSubmit();
        setIsTransactionInProgress(false);
        setIshasConfirmed(false)
        // hasConfirmed = false;
      }
    } catch (error) {
      setIsTransactionInProgress(false);
      console.error("Error:", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      onClose();
    }

    if (e.key === "Enter" && !hasConfirmed && !isTransactionInProgress) {
      setIshasConfirmed(true)
      setIsTransactionInProgress(true)
      handleConfirm();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [amount,cashDifference, isTransactionInProgress, hasConfirmed]);

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50  backdrop-blur-md drop-shadow-sm">
      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"></div>
      <section
        className=" relative flex flex-col px-12 py-12 text-lg font-semibold leading-6 bg-white rounded-[25px] text-zinc-800 max-md:px-5 z-50"
        style={{ width: "600px" }}
      >
        <header className="flex gap-5 text-3xl max-md:flex-wrap max-md:max-w-full">
          <h1 className="flex-auto my-auto">{t(title)}</h1>
          <button
            type="button"
            onClick={onClose}
            className="absolute top-4 right-3 text-zinc-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </header>
        <main>
          <InputField
            label="Amount"
            placeholder="Enter Amount"
            value={amount}
            inputRef={amountInputRef}
            onEnterPress={() => {title === "Cash Out" && parseFloat(amount) > -cashDifference
              ? toast.error(t("Not enough cash"))
              : handleConfirm()}}
              onChange={(value) => {
                // Replace "ز" (Arabic dot) with the decimal point "."
                value = value.replace(/ز/g, ".");
            
                if (value.match(/^\d*\.?\d{0,2}$/) && value < 10000000) {
                  setAmount(value);
                }
              }}             
            //   onChange={(value) => {
            //   if (value.match(/^\d*\.?\d{0,2}$/) && value < 10000000) {
            //     setAmount(value);
            //   }
            // }}
            // type="number"
          />
          <InputField
            label="Reason"
            placeholder="Enter Reason"
            value={reason}
            onEnterPress={() => {title === "Cash Out" && parseFloat(amount) > -cashDifference
              ? toast.error(t("Not enough cash"))
              : handleConfirm()}} 
            onChange={setReason}
          />
        </main>
        <footer>
          <button
            disabled={amount <= 0}
            className={`justify-center items-center px-16 py-3.5 mt-16 text-xl text-center text-white capitalize whitespace-nowrap bg-[#7EC242] rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full`}
            style={{ width: "500px", opacity: amount > 0 ? 1 : 0.5 }}
            onClick={() =>
              title === "Cash Out" && parseFloat(amount) > -cashDifference
                ? toast.error(t("Not enough cash"))
                : handleConfirm()
            }
          >
            {t("Confirm")}
          </button>
        </footer>
      </section>
    </div>
  );
}

export default CashIn_Modal;
