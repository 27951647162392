import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import { Alert, Space } from 'antd';
import { toast } from "react-toastify";
import { t } from "i18next";

export const loginUserRequest = () => ({ type: "LOGIN_USER_REQUEST" });
export const loginUserSuccess = (user) => ({
  type: "LOGIN_USER_SUCCESS",
  payload: user,
});
export const loginUserFailure = (error) => ({
  type: "LOGIN_USER_FAILURE",
  payload: error,
});

export const loginUser = (login, password) => async (dispatch) => {
  dispatch(loginUserRequest());
  let email;
  let mobile;
  if (/^\d{10}$/.test(login)) {
    email = "";
    mobile = login;
  } else {
    email = login;
    mobile = "";
  }
  try {
    const response = await axiosInstance.post(URLS.LOGIN_API_URL, {
      email: email.toLowerCase(),
      password: password,
      mobile: mobile,
    });
  // console.log("response==>", response?.data?.result?.session_status)
    if (response?.data?.result?.status_code === 200) {
      if (response?.data?.result?.session_status == "new") {
        dispatch(loginUserSuccess(response?.data));
      } else {
        toast.error(t("Session is already running, please close the session first!"))
      }
    }else{
      toast.error(t(response?.data?.result?.status))
    }
    return response
  } catch (error) {
    dispatch(loginUserFailure(error.message));
    return error
  }
};

export const openingCash = (session_id, cashbox_value) => async (dispatch) => {
  
  try {
    const response = await axiosInstance.post(URLS.OPENING_CASH, {
      session_id: session_id,
      cashbox_value: cashbox_value ? JSON.parse(cashbox_value) : 0,
      notes: "updated cash",
    });
  // console.log("response==>", response?.data?.result?.session_status)
    if (response?.data?.result?.status_code === 200) {
    return true;
    }else{
      return false;

    }
  } catch (error) {
    dispatch(loginUserFailure(error.message));
    return error
  }
};


export const openSessionInfo = (user_id, company_id) => async (dispatch) => {
  
  try {
    const response = await axiosInstance.post(URLS.OPEN_SESSION_INFO, {
      user_id: user_id,
      company_id: company_id,
    });
  // console.log("response==>", response?.data?.result?.session_status)
    if (response?.data?.result?.status === 200) {
    return response?.data?.result?.response[0];
    }
  } catch (error) {
    dispatch(loginUserFailure(error.message));
    return error
  }
};

export const closePosSession = (payloads) => async (dispatch) => {
  try {
    const response = await axiosInstance.post(URLS.POS_CLOSE_SESSION,payloads);
  // console.log("response==>", response?.data?.result?.status)
    if (response?.data.result?.status === 200) {
      Object.keys(localStorage).forEach(key => {
        if (key !== 'selectedLanguage') {
          localStorage.removeItem(key);
        }
      });
      window.location.reload();
    }
  } catch (error) {
    // dispatch(loginUserFailure(error.message));
    return error
  }
};