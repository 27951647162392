import React from 'react'
import ProductCo from './ProductCo'
import { TotalAmountProvider } from './TotalAmountContext'
import { ItemCountProvider } from './ItemCountContext'

function Session() {
  return (
    <div className='bg-[#EEEEEE]'>
      <TotalAmountProvider>
        <ItemCountProvider>
          <ProductCo />
        </ItemCountProvider>
      </TotalAmountProvider>
    </div>
  )
}

export default Session