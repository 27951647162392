import { ADD_DRAFT_ORDER, REMOVE_DRAFT_ORDER, SELECT_DRAFT_ORDER, CLEAR_DRAFT_ORDERS } from '../Action/draftOrdersAction.jsx';

const initialState = {
  draftOrders: [],
};

const draftOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DRAFT_ORDER:
      return {
        ...state,
        draftOrders: [...state.draftOrders, action.payload],
      };
    case REMOVE_DRAFT_ORDER:
      return {
        ...state,
        draftOrders: state.draftOrders.filter(order => order.id !== action.payload),
      };
    case SELECT_DRAFT_ORDER:
      return {
        ...state,
        draftOrders: state.draftOrders.filter(order => order.id !== action.payload),
      };
    case CLEAR_DRAFT_ORDERS:
      return {
        ...state,
        draftOrders: [],
      };
    default:
      return state;
  }
};

export default draftOrderReducer;
