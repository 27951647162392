const initialState = {
    forRefund: false,
  };
  
  const refundReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_FOR_REFUND':
        return {
          ...state,
          forRefund: action.payload,
        };
      default:
        return state;
    }
  };

  export default refundReducer;