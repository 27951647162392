import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../../Redux/Action/loginAction";
import { hide_password_icon, logo_login, my_ginne_logo, show_password_icon } from "../../assets";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../Modal/language-switcher";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";


const LoadingSpinner = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-50">
      <div className="spinner">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};


const LoginPage = ({ onLogin }) => {
  const { t,i18n } = useTranslation();
  const [login, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [isButtonDisabled , setIsButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!validateEmail(e.target.value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!validateEmail(login)) {
      setEmailError("Invalid email address");
      return;
    }
    setIsButtonDisabled(true); // Disable the button
    setLoading(true);
    try {
      const response = await dispatch(loginUser(login, password));
      // console.log("response", response);
    } catch (error) {
      console.error("Error logging in:", error);
    }finally{
      setLoading(false);
      setIsButtonDisabled(false); // Enable the button


    }
  };

  const handleLangChange = async (option) => {
    if (option.value != i18n.language) {
      // await axiosInstance.post(URLS.CHANGE_LANGUAGE, {
      //   code: option.value,
      // });
      i18n.changeLanguage(option.value); // Change language using i18next
      localStorage.setItem("selectedLanguage", option.value); // Save selected language to localStorage
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <div className="background-image LoginPage items-center justify-center ">
      {/* <div className="flex flex-col items-center justify-center px-16 py-16"> */}
      <div className=" absolute end-10 top-5">
      <LanguageSwitcher handleLangChange={handleLangChange} theme="dark" />

      </div>
      {loading && <LoadingSpinner />}
      <div className={`flex flex-col mt-4 items-center justify-center px-16 py-16 ${loading ? "opacity-50 pointer-events-none" : ""}`}>
        <div className="w-[140%] h-[180%] bg-black bg-opacity-20 backdrop-blur items-center justify-center rounded-lg shadow-lg  bg-black-80 ">
          <div className="p-8 space-y-6 md:space-y-20 sm:p-10">
            {/* <div className="flex items-center justify-center mb-10">
              <img className="" src={my_ginne_logo} alt="logo" />
            </div> */}
            <h1 className="flex flex-col text-4xl font-semibold mt-10 text-white text-center">
              {t("Welcome to Cashier")}
              {/* <span className={`text-2xl mb-5 font-medium leading-tight tracking-tight text-gray-700 text-center ${i18n.language == 'ar' ? ' mx-20' : ''}`}>
                {t("Join us and take your store to the next level!")}
              </span> */}
            </h1>
            <form className="space-y-4 md:space-y-4" onSubmit={handleLogin}>
              <div>
                {/* <label className="block mb-2 text-lg font-medium text-white">
                  {t("Email Address")}
                </label> */}
                <input
                  type="login"
                  name="login"
                  id="login"
                  autoFocus={true}
                  style={{ direction: 'ltr' }}
                  className="bg-gray-50 border text-left border-gray-300 text-gray-900 sm:text-l mb-2 rounded-lg focus:outline-none block w-full p-4 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black"
                  placeholder={t("Email Address")}
                  value={login}
                  onChange={handleEmailChange}
                  // required
                />
                {emailError && <p className="text-red-500 mb-5 text-sm">{t(emailError)}</p>}
              </div>
              <div className="relative">
                {/* <label className="block mb-2 text-lg font-medium text-white ">
                  {t("Password")}
                </label> */}
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  style={{ direction: 'ltr' }}
                  className="bg-gray-50 border mt-6 text-left border-gray-300 text-gray-900 mb-5 sm:text-l rounded-lg focus:outline-none block w-full p-4 pr-12 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black"
                  placeholder={t("Password")}
                  value={password}
                  onChange={handlePasswordChange}
                  // required
                />
                <span
                  onClick={toggleShowPassword}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  style={{ top: "52%", transform: "translateY(-50%)" }}
                >
                  {showPassword ? (
                    <img className="h-6 w-6" src={hide_password_icon} alt="hide password" />
                  ) : (
                    <img className="h-6 w-6" src={show_password_icon} alt="show password" />
                  )}
                </span>
              </div>
              <button
                type="submit"
                disabled={isButtonDisabled} 
                className="w-full text-[#] mt-5 font-bold bg-[#FCC71D] hover:bg-[#FCC71D] focus:ring-4 focus:outline-none focus:ring-[#FCC71D] rounded-lg text-xl px-5 py-4 text-center dark:bg-[#FCC71D] dark:hover:bg-[#FCC71D] dark:focus:ring-[#FCC71D]"
              >
                {t("Login")}
              </button>
            </form>
            <div className="flex items-center justify-center mb-10">
              <img className="" src={logo_login} alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
