import React, { createContext, useContext, useState } from 'react';

const ItemCountContext = createContext();

export const useItemCount = () => useContext(ItemCountContext);

export const ItemCountProvider = ({ children }) => {
    const [itemCount, setItemCount] = useState(0);

    return (
        <ItemCountContext.Provider value={{ itemCount, setItemCount }}>
            {children}
        </ItemCountContext.Provider>
    );
};
