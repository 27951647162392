import * as React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import { useSelector, useDispatch } from "react-redux";
import {
  bank_method_icon,
  cash_method_icon,
  clear_icon,
  return_icon,
} from "../../assets";
import { createOrder } from "../../Redux/Action/createposorderAction";
import { useTranslation } from "react-i18next";
// import LoadingSpinner from './LoadingSpinner'; // Import LoadingSpinner component

const LoadingSpinner = () => {
  return (
    <div
      style={{ zIndex: 50 }}
      className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50"
    >
      <div className="spinner">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

// const LoadingSpinner = () => {
//     return (
//       <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-50">
//         <div className="spinner">
//           <span></span>
//           <span></span>
//           <span></span>
//         </div>
//       </div>
//     );
//   };

function PaymentButton({ paymentMethod, selected, onClick,disabled }) {
  const { t, i18n } = useTranslation();

  const getIcon = (method) => {
    switch (method) {
      case "Cash":
        return cash_method_icon;
      case "Bank":
        return bank_method_icon;
      default:
        return null;
    }
  };

  const getPaymentMethodName = (method) => {
    switch (method) {
      case "Cash":
        return t("Cash Payment");
      case "Bank":
        return t("Bank Payment");
      default:
        return null;
    }
  };

  return (
    <div
      // className={`flex items-center justify-center gap-2 mt-8 px-10 py-1 text-l font-semibold tracking-wide rounded-lg border-2 border-solid shadow-sm basis-auto grow-0 max-md:px-5 cursor-pointer whitespace-nowrap ${
      //   selected
      //     ? "text-blue-900 bg-white border-blue-900"
      //     : "bg-white border border-solid border-stone-300 text-zinc-600"
      // }`}
      // onClick={onClick}
      className={`flex items-center justify-center gap-2 mt-8 px-10 py-1 text-l font-semibold tracking-wide rounded-lg border-2 border-solid shadow-sm basis-auto grow-0 max-md:px-5 cursor-pointer whitespace-nowrap ${
        selected
          ? "text-blue-900 bg-white border-blue-900"
          : "bg-white border border-solid border-stone-300 text-zinc-600"
      } ${disabled ? "opacity-50 cursor-not-allowed" : ""}`} // Add this class
      onClick={onClick}
      disabled={disabled} // Add this prop
    >
      {getIcon(paymentMethod.name) && (
        <img
          src={getIcon(paymentMethod.name)}
          alt={`${paymentMethod.name} icon`}
          className={`w-auto mr-2 ${selected ? "" : "filter grayscale"}`}
        />
      )}
      {getPaymentMethodName(paymentMethod.name)}
    </div>
  );
}

function KeypadButton({
  value,
  className,
  onClick,
  children,
  isBankPaymentSelected,
  grandTotal,
}) {
  return (
    <button
      type="button"
      className={`flex justify-center items-center h-[94px] w-40 rounded ${
        isBankPaymentSelected || grandTotal < 0? "opacity-50" : ""
      } ${className}`}
      onClick={isBankPaymentSelected ? null : onClick}
      disabled={isBankPaymentSelected || grandTotal < 0}
    >
      {children || value}
    </button>
  );
}

function Payment_Modal({ onClose, grandTotal, handleCheckout, setTenderCash }) {
  const { t, i18n } = useTranslation();
  const { user } = useSelector((state) => state?.login);
  const [totalPaid, setTotalPaid] = useState("");
  const [ReturnTotal, setReturnTotal] = useState("");
  const [change, setChange] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [isBankPaymentSelected, setIsBankPaymentSelected] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [activePaymentIndex, setActivePaymentIndex] = useState(0);
  const returnProducts = useSelector((state) => state.returnProductReducer.products);

  const navigate = useNavigate(); // Using useNavigate hook for navigation
  // console.log("totalPaid-->", totalPaid);
  useEffect(() => {
    getPaymentMethod(user?.result?.user_id);
  }, [user?.result?.user_id]);

  const getPaymentMethod = async (userID) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(URLS.PAYMENT_METHODS, {
        user_id: userID,
      });
      // console.log("getPaymentMethod--> ", response?.data?.result?.data);
      if (response?.data.result.data != null) {
        setPaymentMethods(response?.data?.result?.data);
        setSelectedPaymentMethod(response?.data?.result?.data[0]);
        setActivePaymentIndex(0);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const keypadValues = [
    [
      {
        value: "1",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "2",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "3",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "10",
        className:
          "text-white  bg-gradient-to-b from-[#97DD59] via-[#97DD59] to-[#6BB12C]  border border-[#97DD59] hover:bg-lime-600",
      },
    ],
    [
      {
        value: "4",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "5",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "6",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "20",
        className:
          "text-white  bg-gradient-to-b from-[#97DD59] via-[#97DD59] to-[#6BB12C]  border border-[#97DD59] hover:bg-lime-600",
      },
    ],
    [
      {
        value: "7",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "8",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "9",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "50",
        className:
          "text-white  bg-gradient-to-b from-[#97DD59] via-[#97DD59] to-[#6BB12C]  border border-[#97DD59] hover:bg-lime-600",
      },
    ],
    [
      {
        value: ".",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "0",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "200",
        className:
          "text-white  bg-gradient-to-b from-[#97DD59] via-[#97DD59] to-[#6BB12C]  border border-[#97DD59] hover:bg-lime-600",
      },
      {
        value: "100",
        className:
          "text-white  bg-gradient-to-b from-[#97DD59] via-[#97DD59] to-[#6BB12C]  border border-[#97DD59] hover:bg-lime-600",
      },
    ],
  ];

  const handleKeypadButtonClick = (value) => {
    if (
      totalPaid.length >= 10 &&
      value !== "C" &&
      value !== "." &&
      !["50", "100", "200"].includes(value)
    ) {
      return;
    }

    if (value === "C") {
      setTotalPaid("");
      setTenderCash("");
      setChange("");
    } else if (value === ".") {
      if (!totalPaid.includes(".")) {
        setTotalPaid((prevTotalPaid) => prevTotalPaid + ".");
        setTenderCash((prevTotalPaid) => prevTotalPaid + ".");
      }
    } else if (!isNaN(parseInt(value, 10))) {
      if (["50", "100", "200", "20", "10"].includes(value)) {
        const updatedTotalPaid = parseFloat(totalPaid || 0) + parseFloat(value);
        setTotalPaid(updatedTotalPaid.toString());
        setTenderCash(updatedTotalPaid.toString());
        const changeNumeric =
          updatedTotalPaid -
          parseFloat(String(grandTotal).replace(/[^0-9.]/g, ""));
        setChange(`${Math.max(changeNumeric, 0)}`);
      } else {
        const updatedTotalPaid = totalPaid + value;
        setTotalPaid(updatedTotalPaid);
        setTenderCash(updatedTotalPaid);
        const numericValue = parseFloat(
          updatedTotalPaid.replace(/[^0-9.]/g, "")
        );
        const changeNumeric =
          numericValue - parseFloat(String(grandTotal).replace(/[^0-9.]/g, ""));
        setChange(`${Math.max(changeNumeric, 0)}`);
      }
    }
    setErrorMessage("");
  };

  const handlePaymentMethodClick = (method) => {
    if (grandTotal <= 0 && method.name === "Bank") return;
    setSelectedPaymentMethod(method);
    setActivePaymentIndex(paymentMethods.indexOf(method));

    if (method.name === "Bank") {
      setIsBankPaymentSelected(true);
      setTenderCash(String(grandTotal));
      setTotalPaid(String(grandTotal));
      setChange("0");
    } else {
      setIsBankPaymentSelected(false);
      setTenderCash("");
      setTotalPaid("");
      setChange("");
    }
    setErrorMessage("");
  };

  // const handleCheckout = async () => {

  //     navigate("/OrderCheckout");
  // };

  const handleCheckoutClick = (event) => {
    // if (!totalPaid) {
    //   event.preventDefault();
    //   // setErrorMessage(t("Please enter total paid amount."));
    // } else {
      handleCheckout(selectedPaymentMethod);
    // }
  };

  const handleClearButtonClick = () => {
    if (!isBankPaymentSelected) {
      setTotalPaid("");
      setChange("");
    }
  };

  useEffect(() => {
    if (grandTotal < 0) {
      setTotalPaid(grandTotal.toString());
      setIsBankPaymentSelected(true);
    }
  }, [grandTotal]);

  const handleKeyPress = (event) => {
    const normalizedKey =
      {
        // ش: "Enter", // Arabic "ش" is similar to English "Enter"
        x: "ء", // Arabic "Ctrl+X" will be normalized to "x" when Ctrl is pressed
      }[event.key] || event.key; // Fallback to the original key if no mapping exists

    if (event.key === "Enter") {
      event.preventDefault();
      if (totalPaid > grandTotal || totalPaid == grandTotal) handleCheckoutClick(event);
    } else if (event.shiftKey && normalizedKey === "Shift") {
      event.preventDefault();
  if (grandTotal <= 0) {
    setActivePaymentIndex(0);
  } else {
    let newIndex;
    if (activePaymentIndex === 0) {
      newIndex = 1;
    } else if (activePaymentIndex === 1) {
      newIndex = 0;
    }
    setActivePaymentIndex(newIndex);
    setSelectedPaymentMethod(paymentMethods[newIndex]);
    handlePaymentMethodClick(paymentMethods[newIndex]);
  }
    } else if (event.ctrlKey && normalizedKey === "ء") {
      // Handle Ctrl+X
      event.preventDefault();
      handleClearButtonClick();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    totalPaid,
    grandTotal,
    selectedPaymentMethod,
    activePaymentIndex,
    handleClearButtonClick,
  ]);


  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 backdrop-blur-md drop-shadow-sm">
      {loading && <LoadingSpinner />}

      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"></div>
      <section
        className="relative flex flex-col px-12 py-10 text-lg font-semibold  bg-white rounded-[15px] text-zinc-800 max-md:px-5 z-40"
        style={{ width: "1000px" }}
      >
        <button
          type="button"
          onClick={onClose}
          className="absolute top-4 right-3 text-zinc-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-8 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <main>
          <div className="flex flex-1 gap-10 max-md:flex-col">
            <div className="flex flex-col w-1/3 max-md:w-full">
              <div className="flex flex-col gap-3">
                <div className="text-2xl font-semibold tracking-wider text-[#5C5C5C]">
                  {t("Grand Total")}
                </div>

                <div className="text-2xl font-bold mt-5 tracking-wider text-[#5C5C5C] mt-2">
                  {t("Total Paid")}
                </div>
                <div className="text-2xl font-bold  mt-5 tracking-wider text-[#5C5C5C] mt-2">
                  {t("Change")}
                </div>

                {/* Payment Buttons  */}
                <div className="flex flex-col capitalize max-md:flex-wrap">
                  {paymentMethods?.slice(0, 2)?.map((method, index) => (
                    <PaymentButton
                      key={index}
                      paymentMethod={method}
                      selected={
                        selectedPaymentMethod?.name === method?.name ||
                        activePaymentIndex === index
                      }
                      onClick={() => handlePaymentMethodClick(method)}
                      disabled={grandTotal <= 0 && method.name === "Bank"}
                      style={{
                        opacity: grandTotal <= 0 && method.name === "Bank" ? 0.5 : 1, // Add this style
                        pointerEvents: grandTotal <= 0 && method.name === "Bank" ? "none" : "auto", // Add this style
                      }}
                    />
                  ))}
                </div>

                {/* Checkout Button  */}

                {errorMessage && (
                  <div className="text-red-500 text-center mt-8">
                    {errorMessage}
                  </div>
                )}
                <button
                  disabled={totalPaid < grandTotal}
                  onClick={handleCheckoutClick}
                  className="justify-center items-center py-24 mt-4 text-3xl text-center text-white capitalize whitespace-nowrap bg-gradient-to-b from-[#64B5FF] to-[#1D5C96] rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full"
                  style={{
                    // width: "70px",
                    opacity: totalPaid < grandTotal ? 0.5 : 1,
                  }}
                >
                  {t("Done")}
                </button>
              </div>
            </div>

            <div className="flex flex-col w-2/3 max-md:w-full">
              {/* Grand Total  */}

              {/* Tota Paid  */}
              <div className="flex flex-row gap-8">
                <div>
                  <div
                    className={`flex h-[40px] overflow-hidden justify-center items-start ${
                      i18n.language === "ar" ? "flex-row-reverse " : ""
                    }`}
                  >
                    <span className="inset-y-0 flex items-center pl-3 text-2xl text-black">
                      EGP
                    </span>
                    <input
                      type="text"
                      className="text-left  w-full px-2 font-bold text-zinc-800 text-2xl"
                      // value={`${grandTotal}`}
                      // value={parseFloat(grandTotal).toFixed(2)}
                      value={i18n.language === "ar" && grandTotal < 0
                        ? `${-grandTotal.toFixed(2)}-`
                        : parseFloat(grandTotal).toFixed(2)}
                      readOnly
                    />
                  </div>
                  <div
                    className={`flex bg-white mt-1 rounded-lg border border-solid border-stone-300 h-[60px] overflow-hidden justify-center items-start ${
                      i18n.language === "ar" ? "flex-row-reverse" : ""
                    }`}
                  >
                    <span className="inset-y-0 flex items-center pl-3 py-4 text-2xl text-black">
                      EGP
                    </span>
                    <input
                      type="text"
                      autoFocus={true}
                      className="bg-white text-left h-[62px] w-full focus:outline-none px-2 font-bold text-zinc-800 text-2xl"
                      // value={totalPaid === "" ? "" : `${totalPaid}`}
                      // value={grandTotal <= 0 ? "0" : totalPaid} // Set 0 when grandTotal <= 0
                      value={isBankPaymentSelected ? grandTotal.toFixed(2) : grandTotal <= 0 ? "0" : totalPaid}
                      readOnly={isBankPaymentSelected || grandTotal < 0}
                      style={{ direction: "ltr" }}
                      onChange={(e) => {
                        // Get the input value
                        let inputValue = e.target.value;

                        // Replace Arabic decimal separator with a period
                        inputValue = inputValue.replace(/ز/g, ".");

                        // Remove any non-numeric characters except the decimal point
                        const sanitizedValue = inputValue.replace(
                          /[^0-9.]/g,
                          ""
                        );

                        // Validate the sanitized value
                        if (/^\d*\.?\d{0,2}$/.test(sanitizedValue)) {
                          setTotalPaid(sanitizedValue);
                          setTenderCash(sanitizedValue);

                          // Calculate the change
                          const numericValue = parseFloat(sanitizedValue) || 0;
                          const grandTotalValue =
                            parseFloat(
                              String(grandTotal).replace(/[^0-9.]/g, "")
                            ) || 0;
                          const changeNumeric = numericValue - grandTotalValue;

                          // Set the change ensuring it's not negative
                          setChange(`${Math.max(changeNumeric, 0)}`);
                        }
                      }}
                      maxLength={7}
                    />

                    {/* <input
                    type="text"
                    autoFocus={true}
                    className="bg-white text-left h-[62px] w-full focus:outline-none px-2 font-bold text-zinc-800 text-2xl"
                    value={totalPaid === "" ? "" : `${totalPaid}`}
                    readOnly={isBankPaymentSelected}
                    style={{ direction: "ltr" }}
                    onChange={(e) => {
                      const inputValue = e.target.value.replace(/[^0-9.]/g, "");
                      if (e.target.value.match(/^\d*\.?\d{0,2}$/)) {
                        setTotalPaid(inputValue);
                        setTenderCash(inputValue);
                        const changeNumeric =
                          inputValue -
                          parseFloat(
                            String(grandTotal).replace(/[^0-9.]/g, "")
                          );
                        setChange(`${Math.max(changeNumeric, 0)}`);
                      }
                    }}
                    maxLength={7}
                  /> */}
                  </div>
                  <div
                    className={`flex rounded-lg mt-2  w-[300px] h-[60px] overflow-hidden justify-center items-start ${
                      i18n.language === "ar" ? "flex-row-reverse" : ""
                    }`}
                  >
                    <span className="inset-y-0 flex items-center pl-3 py-4 text-2xl text-black">
                      EGP
                    </span>
                    <input
                      type="text"
                      className="text-left h-[62px] w-full focus:outline-none px-2 font-bold text-zinc-800 text-2xl"
                      // value={
                      //   change % 1 === 0 ? change : Number(change)?.toFixed(2)
                      // }
                      // value={
                      //   grandTotal < 0 
                      //     ? grandTotal // Display grandTotal when it's less than 0
                      //     : change % 1 === 0 
                      //       ? change 
                      //       : Number(change)?.toFixed(2) // Format change with two decimal places
                      // }
                      value={i18n.language === "ar" && grandTotal < 0
                        ? `${-grandTotal.toFixed(2)}-`
                        : grandTotal < 0 
                          ? grandTotal.toFixed(2) // Display grandTotal when it's less than 0
                          : Number(change)?.toFixed(2) // Format change with two decimal places
                      }
                      readOnly
                      // value={i18n.language === "ar" && grandTotal < 0
                      //   ? `${-grandTotal}-`
                      //   : grandTotal < 0 
                      //     ? grandTotal // Display grandTotal when it's less than 0
                      //     : change % 1 === 0 
                      //       ? change 
                      //       : Number(change)?.toFixed(2) // Format change with two decimal places
                      // }
                      // readOnly
                    />
                  </div>
                </div>

                <button
                  className={`justify-center items-center text-xl text-white bg-gradient-to-b from-red-400 to-red-600 mt-11 w-[500px] h-[60px] rounded-lg border border-solid border-stone-300 max-md:px-5 ${
                    isBankPaymentSelected || grandTotal < 0 ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={
                    isBankPaymentSelected ? null : handleClearButtonClick
                  }
                  style={{ opacity: isBankPaymentSelected  || grandTotal < 0 ? 0.5 : 1 }} // Apply inline style for opacity
                >
                  {t("Clear")}
                </button>
              </div>

              {/* Change  */}

              <div className="flex flex-col mt-8 text-4xl font-bold tracking-wider text-center capitalize text-zinc-800 max-md:mt-7 max-md:w-full">
                {keypadValues.map((row, rowIndex) => (
                  <div
                    key={rowIndex}
                    className="flex gap-2 mb-3 max-md:flex-wrap max-md:w-full"
                  >
                    {row.map((value, index) => (
                      <KeypadButton
                        key={index}
                        value={value.value}
                        className={value.className}
                        onClick={() => handleKeypadButtonClick(value.value)}
                        isBankPaymentSelected={isBankPaymentSelected}
                        style={value.isIcon ? { padding: "0.1rem" } : null}
                        grandTotal={grandTotal}
                      >
                        {value.isIcon ? (
                          <img
                            src={value.icon}
                            alt="icon"
                            style={{ height: "1.5rem", width: "auto" }}
                          />
                        ) : (
                          value.value
                        )}
                      </KeypadButton>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </main>
        <footer>
          {/* {errorMessage && (
            <div className="text-red-500 text-center mt-8">{errorMessage}</div>
          )}
          <button
            disabled={totalPaid < grandTotal}
            onClick={handleCheckoutClick}
            className="justify-center items-center px-16 py-10 mt-10 text-3xl text-center text-white capitalize whitespace-nowrap bg-blue-900 rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full"
            style={{
              width: "700px",
              opacity: totalPaid < grandTotal ? 0.5 : 1,
            }}
          >
            {t("Checkout")}
          </button> */}
        </footer>
      </section>
    </div>
  );
}

export default Payment_Modal;
