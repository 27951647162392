import { combineReducers } from "redux";
import {
  userReducer,
  loginReducer,
  sessionidentifierReducer,
  getcategorydataReducer,
  storeOrdersReducer,
  productReducer,
  customerReducer,
  orderlinesReducer,
  createorderReducer,
  orderReceiptReducer,
  productsReducer,
  draftOrderReducer,
  returnProductReducer,
  refundReducer,
} from "./index";

const rootReducer = combineReducers({
  user: userReducer,
  login: loginReducer,
  sessionIdentify: sessionidentifierReducer,
  productData: getcategorydataReducer,
  orders: storeOrdersReducer,
  updatefavproduct: productReducer,
  getcustomer: customerReducer,
  orderlinesReducer: orderlinesReducer,
  createorderReducer: createorderReducer,
  orderReceiptReducer: orderReceiptReducer,
  products: productsReducer,
  draftOrders: draftOrderReducer,
  returnProductReducer: returnProductReducer,
  refundReducer: refundReducer,
});

export default rootReducer;
