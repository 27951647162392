export const fetchUserRequest = () => ({ type: 'FETCH_USER_REQUEST' });
export const fetchUserSuccess = (user) => ({ type: 'FETCH_USER_SUCCESS', payload: user });
export const fetchUserFailure = (error) => ({ type: 'FETCH_USER_FAILURE', payload: error });

export const fetchUser = () => async (dispatch) => {
  dispatch(fetchUserRequest());
  try {
    const response = await fetch('https://jsonplaceholder.typicode.com/todos/1');
    const data = await response.json();
       dispatch(fetchUserSuccess(data?.title));
  } catch (error) {
    dispatch(fetchUserFailure(error.message));
  }
};
