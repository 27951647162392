// src/Redux/Reducer/orderReducer.js

import {
    GET_POS_ORDER_LINES_REQUEST,
    GET_POS_ORDER_LINES_SUCCESS,
    GET_POS_ORDER_LINES_FAILURE,
} from '../Action/orderlinesAction';

const initialState = {
    orderLines: [],
    loading: false,
    error: null,
};

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_POS_ORDER_LINES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_POS_ORDER_LINES_SUCCESS:
            return {
                ...state,
                loading: false,
                orderLines: action.payload,
                userId: action.payload.userId,
            };
        case GET_POS_ORDER_LINES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default orderReducer;
