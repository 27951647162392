export const URLS = {
  LOGIN_API_URL: "UserLogin",
  OPENING_CASH: "Pos/OpeiningCash",
  SEARCH_PRODUCT:"ProductSearch",
  OPEN_SESSION_INFO: "PosOpenSessionData",
  POS_CLOSE_SESSION: "PosCloseSession",
  GET_CUSTOMER_DETAILS:"GetCustomerdetails",
  CREATE_CUSTOMER: "CreateCustomers",
  UPDATE_CUSTOMER_DATA: "UpdateCustomerData",
  CREATE_ORDER_NEW: "CreatePosOrderNew",
  PAYMENT_METHODS: "PaymentMethod",
  POS_ORDERS: "/PosOrders",
  POS_ORDER_LINES: "PosOrderLines",
  RECEIPT_ORDER_DETAILS: "ReceiptOrderdetails",
  CASH_IN_OUT: "Pos/CashInOut",
  GET_CATEGORIES:"PosGetProductCategory",
  GET_CATEGORY_PROD:"PosGetCategoryProducts",
  GET_USER_DETAILS: "GetUserDetails",
  CHANGE_LANGUAGE: "/UserLanguage",
  SEND_CUSTOMER_SMS: "/SendCustomerSms",
  VERIFY_RETURN: "/CheckProductQuantityInOrderLines",
  REFUND_ORDER: "/PosRefundOrder",
  REFUND_ORDER_PAYMENT: "/PosRefundPayment"
};
