
export const UPDATE_FAV_PRODUCT_SUCCESS = 'UPDATE_FAV_PRODUCT_SUCCESS';


const initialState = {
    favoriteProducts: [],
};

const productReducer = (state = initialState, action) => {
    switch (action.type) {
      case "UPDATE_FAV_PRODUCT_SUCCESS":
        return {
          ...state,
          favoriteProducts: state.favoriteProducts.map(product =>
            product.product_id === action.payload.product_id ? action.payload : product
          )
        };
      default:
        return state;
    }
  };
export default productReducer;