import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import {
  Dashboard,
  Login,
  Navbar,
  OrderCheckout,
  Orders,
  Session,
  UserProfile,
} from "./Components/index";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

const PrivateRoute = ({ children }) => {
  const { user } = useSelector((state) => state?.login);
  return user ? (
    <>
      <Navbar />
      {children}
    </>
  ) : (
    <Navigate to="/login" />
  );
};

const LoginExist = ({ children }) => {
  const { user } = useSelector((state) => state?.login);
  return !user ? children : <Navigate to="/session" />;
};

const ProjectRoutes = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <Router>
      <Routes>
        <Route path={`/dashboard`} element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path={`/orders`} element={<PrivateRoute><Orders /></PrivateRoute>} />       
        <Route path={`/ordercheckout`} element={<PrivateRoute><OrderCheckout /></PrivateRoute>} />
        <Route path={`/session`} element={<PrivateRoute><Session /></PrivateRoute>} />
        <Route path={`/userprofile`} element={<PrivateRoute><UserProfile /></PrivateRoute>} />
        <Route path={`/login`} element={<LoginExist><Login /></LoginExist>} />
        {/* Redirect root path to language-specific path */}
        <Route path="/" element={<Navigate to={`/session`} />} />
        {/* Catch-all route to handle unknown paths */}
        <Route path="*" element={<Navigate to={`/session`} />} />
      </Routes>
    </Router>
  );
};

export default ProjectRoutes;
