import React, { useEffect, useRef, useState } from "react";
import { Badge } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateFavProduct } from "../../../Redux/Action/updateFavAction";
import { useTranslation } from "react-i18next";

const ProductCard = ({ product, onClick, isFocused }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const storeId = useSelector((state) => state.login.user.store_id);
  const { user } = useSelector((state) => state?.login);

  // console.log("product--->",product)
  const toggleFavorite = async (event) => {
    event.stopPropagation();

    const newFavoriteStatus = !product.isfav;

    try {
      await dispatch(
        updateFavProduct(product.product_id, storeId, newFavoriteStatus)
      );
    } catch (error) {
      console.error("Failed to update favorite status:", error);
    }
  };

  return (
    <div
      className={`flex flex-col mt-3 me-2 w-[15.5%] 2xl:w-[12.5%] h-48 transform transition-transform duration-300 ${
        isFocused ? "shadow-[1px_3px_3px_1px_#182529] -translate-y-2" : "shadow-lg"
      }`}
      onClick={onClick}
    >
      <div className="relative flex flex-col p-1 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-500 ease-in-out cursor-pointer h-full">
        {/* <button className="absolute top-1 right-1 focus:outline-none" onClick={toggleFavorite}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-7 h-7"
                        viewBox="0 0 24 24"
                        fill={product.isfav ? "gold" : "grey"}
                    >
                        <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                        <path d="M0 0h24v24H0z" fill="none" />
                    </svg>
                </button> */}
        <div className="flex justify-center items-center p-3 rounded-md ">
          <Badge
            className={`absolute top-2 left-2 ${
              product.qty_available <= 0 ? " bg-red-600" : "bg-green-800"
            } text-white font-semibold w-fit px-1 rounded lg`}
          >
            <span>
              <span>
                {i18n.language === "ar" && parseFloat(product.qty_available) < 0
                  ? `${parseFloat(-product.qty_available).toFixed(2)}-`
                  : parseFloat(product.qty_available).toFixed(2)}
              </span>
            </span>
          </Badge>
          <img
            loading="lazy"
            src={product.image_url}
            className="h-24 w-auto object-contain cursor-pointer"
            alt={product.name}
          />
        </div>
        <div className="text-sm font-semibold text-gray-800 line-clamp-2">
          {product.name}
        </div>
        <div className="flex-grow" />
        <div className="mt-1 text-sm font-bold text-blue-900 mb-0">
          EGP {product.sale_price}
        </div>
      </div>
    </div>
  );
};

const ProductRow = ({ onSelectProduct, products, focusedProductIndex }) => {

  // if (products.length === 0) {
  //   return <NoProductsFound />;
  // }
  // const { Productdata } = useSelector((state) => state.productData)
  const rowRef = useRef(null);

  useEffect(() => {
    if (
      rowRef.current &&
      focusedProductIndex !== null &&
      focusedProductIndex !== undefined
    ) {
      const focusedProduct = rowRef.current.children[focusedProductIndex];
      if (focusedProduct) {
        focusedProduct.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  }, [focusedProductIndex]);

//   const NoProductsFound = () => {
//     if (products.length === 0) {
//     return <div className="justify-center text-center mt-10 font-semibold text-lg text-gray-600">No product found</div>;
//   };
// }

  //   console.log();
  return (
    <div
      ref={rowRef}
      className="flex flex-wrap  max-h-[95vh] custom-scrollbar overflow-y-scroll scrollbar-hidden"
    >
      {/* <NoProductsFound/> */}
      {products?.map((product, idx) => (
        <ProductCard
          key={idx}
          product={product}
          onClick={() => onSelectProduct(product)}
          isFocused={idx === focusedProductIndex}
        />
      ))}
    </div>
  );
};

const ProductGrid = ({ onSelectProduct, products, focusedProductIndex }) => {
  const { t, i18n } = useTranslation();

  const NoProductsFound = () => {
    if (products.length === 0) {
    return <div className="justify-center text-center mt-32 font-semibold text-lg text-gray-600">{t("Product Not Found")}</div>;
  };
}
  return (
    <div className="p-1">
      <NoProductsFound/>
      <ProductRow
        onSelectProduct={onSelectProduct}
        products={products}
        focusedProductIndex={focusedProductIndex}
      />
    </div>
  );
};

export default ProductGrid;
