import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { add_customer_icon, customers_search_icon } from "../../../assets";
import AddCustomer_Modal from "../../Modal/AddCustomer_Modal";
import {
  fetchCustomers,
  setCustomer,
} from "../../../Redux/Action/getcustomerAction";
import { useTranslation } from "react-i18next";

// const dummyCustomers = [
//     { id: 1, name: "Lakshya", number: "1234567890" },
//     { id: 2, name: "Kashan", number: "0987654321" },
//     { id: 3, name: "Nakul", number: "4567891230" },
//     { id: 4, name: "Harsh", number: "4567891230" },
// ];

export const CustomerSearchBar = ({ removeCustomer }) => {
  const { t, i18n } = useTranslation();
  const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const returnProducts = useSelector(
    (state) => state.returnProductReducer.products
  );

  const dispatch = useDispatch();
  const { customers, loading, error, selectedCustomer } = useSelector(
    (state) => state.getcustomer
  );

  // const openModal = () => setIsAddCustomerModalOpen(true);
  const openModal = () => {
    if (returnProducts.length <= 0) {
      setIsAddCustomerModalOpen(true);
    }
  };
  const closeModal = () => {
    setIsAddCustomerModalOpen(false);
  };

  useEffect(() => {
    if (selectedCustomer?.partnerId) {
      // console.log("CUSTOMER", selectedCustomer);

      setSearchValue(
        `${selectedCustomer.mobile} ${
          selectedCustomer.userName && `(${selectedCustomer.userName})`
        }`
      );
    } else if (returnProducts.length > 0) {
      if (selectedCustomer) {
        setSearchValue(selectedCustomer);
      }
    }
  }, [selectedCustomer, returnProducts]);

  // useEffect(() => {
  //   if (selectedCustomer) {
  //     setSearchValue(selectedCustomer);
  //   }
  // }, [selectedCustomer]);

  const onCustomerSelect = (customer) => {
    // console.log("customer--->", customer)
    if (customer?.partnerId) {
      dispatch(setCustomer(customer));
    }
    setIsAddCustomerModalOpen(false);
    // setSelectedCustomer(customer);
  };

  useEffect(() => {
    if (highlightedIndex >= 0 && highlightedIndex < (customers || []).length) {
      setSearchValue(customers[highlightedIndex].phone);
    }
  }, [highlightedIndex, customers]);

  const handleInputChange = (e) => {
    // onCustomerSelect(false);
    const value = e.target.value;
    setSearchValue(value);
    const limit = 5;
    const offset = 5;
    dispatch(fetchCustomers(String(value), limit, offset));
    setIsDropdownOpen(value.length > 0);
    setHighlightedIndex(-1);
  };

  const handleCustomerSelect = (customer) => {
    setSearchValue(customer.phone);
    setIsDropdownOpen(false);
    // onCustomerSelect(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) =>
        Math.min(prevIndex + 1, (customers || []).length - 1)
      );
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (e.key === "Enter" && highlightedIndex >= 0) {
      handleCustomerSelect(customers[highlightedIndex]);
      setHighlightedIndex(-1);
    } else if (e.shiftKey && e.key === "") {
      openModal();
    }
  };

  useEffect(() => {
    const keyMappings = {
      C: ["C", "}"], // English "C" and Arabic "ك"
    };

    const isKeyMatch = (key, targets) => targets.includes(key);

    const handleGlobalKeyDown = (e) => {
      // if (e.shiftKey && isKeyMatch(e.key, keyMappings.C)) {
        if (e.shiftKey && isKeyMatch(e.key, keyMappings.C) && returnProducts.length <= 0) {
        // e.preventDefault();
        openModal();
      }
    };

    window.addEventListener("keydown", handleGlobalKeyDown);
    return () => {
      window.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, [returnProducts]);

  // useEffect(() => {
  //   const handleGlobalKeyDown = (e) => {
  //     if (e.shiftKey && e.key === "C") {
  //       // e.preventDefault();
  //       openModal();
  //     }
  //   };

  //   window.addEventListener("keydown", handleGlobalKeyDown);
  //   return () => {
  //     window.removeEventListener("keydown", handleGlobalKeyDown);
  //   };
  // }, []);

  return (
    <>
      {isAddCustomerModalOpen && (
        <AddCustomer_Modal
          onClose={closeModal}
          confirm={onCustomerSelect}
          showSkipButton={false}
        />
      )}
      <div className="relative flex w-[100%] border rounded-lg bg-white focus:outline-none">
        <span className="inset-y-0 flex items-center ps-3">
          <img src={customers_search_icon} alt="customers_search_icon" />
        </span>
        <span
         onClick={() => (selectedCustomer?.partnerId ? null : openModal())}
         className={`inset-y-0 cursor-pointer  w-9/12 flex p-2 items-center ps-3 ${
           returnProducts.length <= 0 ? "" : "opacity-60 cursor-not-allowed"
         }`}
          // onClick={() => (selectedCustomer?.partnerId ? null : openModal())}
          // className="inset-y-0 cursor-pointer  w-9/12 flex p-2 items-center ps-3"
        >
          {searchValue && selectedCustomer
            ? searchValue
            : t("Add customer phone number")}
        </span>
        {/* <input
                    disabled={true}
                    type="text"
                    placeholder={t("Add customer phone number")}
                    readOnly={selectedCustomer?.partnerId}
                    value={searchValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    className=" p-2 px-6 rounded bg-white w-9/12 focus:outline-none"
                /> */}
        {selectedCustomer?.partnerId ? (
          <button
            type="button"
            // onClick={(returnProducts.length <= 0) ? () => {} : () => {
            //   setSearchValue("");
            //   removeCustomer();
            // }}
            onClick={() => {
              setSearchValue("");
              removeCustomer();
            }}
            className="text-zinc-800 absolute justify-center end-2 h-full"
            // className={`text-zinc-800 absolute justify-center end-2 h-full ${
            //   returnProducts.length <= 0 ? "" : "opacity-60 cursor-not-allowed"
            // }`}
            // disabled={returnProducts.length <= 0 ? true : false}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        ) : (
          <button
            // onClick={openModal}
            onClick={(returnProducts.length < 0) ? () => {} : openModal}
            className={`absolute inset-y-0 end-0 flex items-center pe-2 w-10 ${
              returnProducts.length <= 0 ? "" : "opacity-60 cursor-not-allowed"}`}
            disabled={returnProducts.length < 0}
          >
            
            <img src={add_customer_icon} alt="right_icon" />
          </button>
        )}

        {isDropdownOpen && (
          <div className="absolute top-full left-0 right-0 bg-white border border-gray-300 rounded shadow-lg z-10">
            {loading && <div className="p-2">Loading...</div>}
            {error && <div className="p-2 text-red-500">Error: {error}</div>}
            {(customers || []).map((customer, index) => (
              <div
                key={index}
                onClick={() => handleCustomerSelect(customer)}
                className={`p-2 cursor-pointer border hover:bg-gray-100 ${
                  highlightedIndex === index ? "bg-gray-200" : ""
                }`}
              >
                {customer.phone}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
