import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";

export const GET_POS_ORDER_LINES_REQUEST = 'GET_POS_ORDER_LINES_REQUEST';
export const GET_POS_ORDER_LINES_SUCCESS = 'GET_POS_ORDER_LINES_SUCCESS';
export const GET_POS_ORDER_LINES_FAILURE = 'GET_POS_ORDER_LINES_FAILURE';

export const getPosOrderLinesRequest = () => ({
    type: GET_POS_ORDER_LINES_REQUEST,
});

export const getPosOrderLinesSuccess = (orderLines) => ({
    type: GET_POS_ORDER_LINES_SUCCESS,
    payload: orderLines,
});

export const getPosOrderLinesFailure = (error) => ({
    type: GET_POS_ORDER_LINES_FAILURE,
    payload: error,
});

export const fetchPosOrderLines = (order_id) => async (dispatch, getState) => {
    dispatch(getPosOrderLinesRequest());
    try {
        const state = getState(); 
        const userId = state?.login?.user?.user_id;
        const lang = localStorage.getItem('selectedLanguage');
        const response = await axiosInstance.post(URLS.POS_ORDER_LINES, { lang,order_id }); // Include orderId and userId in the request
        if(response?.data.result.records){
            dispatch(getPosOrderLinesSuccess(response?.data.result.records, userId));
        }
    } catch (error) {
        dispatch(getPosOrderLinesFailure(error));
    }
};
