import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { setCustomer } from "../../Redux/Action/getcustomerAction";

function InputField({
  label,
  placeholder,
  changed,
  inputType,
  error,
  ...props
}) {
  // const [value, setValue] = useState("");
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="mt-9 font-medium max-md:max-w-full">{label}</div>
      <div
        className={`flex border border-solid rounded-xl overflow-hidden justify-center items-start ${
          inputType === "number" && i18n.language === "ar"
            ? "flex-row-reverse"
            : ""
        }`}
      >
        {inputType === "number" && (
          <span className="pl-3 py-3 bg-white text-black max-md:pe-3">
            {i18n.language === "ar" ? "20+" : "+20"}
          </span>
        )}  
        <input
          {...props}
          type="text"
          placeholder={placeholder}
          style={{ direction: inputType === "number" && "ltr" }}
          onChange={(text) => changed(text)}
          className={`p-3 rounded-xl border-0 border-neutral-400 border-opacity-50 text-black max-md:pr-5 max-md:max-w-full w-full focus:outline-none focus:border-1px ${
            inputType === "text" ? "bg-gray-100" : "bg-white"
          } ${error ? "border-red-500" : ""}`}
        />
      </div>
    </>
  );
}

function AddCustomer_Modal({ onClose, confirm, showSkipButton }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState("");
  const [userName, setName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [customerExist, setCutomerExist] = useState(false);
  const [partnerId, setPartnerId] = useState("");
  const { user_id } = useSelector((state) => state?.login?.user?.result);
  const inputRef = useRef(null);
  const [hasError, setHasError] = useState({
    mobile: false,
    displayName: false,
  });

  // console.log("partnerId", partnerId);
  let len = 10;

  if (/^0/.test(mobile)) {
    len = 11;
  }

  // console.log("len", len, mobile.length);
  // const handleConfirm = async () => {
  //   if (mobile) {
  //     let formattedMobile = mobile;
  //     if (formattedMobile.startsWith("0")) {
  //       formattedMobile = formattedMobile.slice(1);
  //     }

  //     try {
  //       const response = await axiosInstance.post(URLS.CREATE_CUSTOMER, {
  //         mobile: formattedMobile,
  //         user_id: user?.user_id,
  //       });
  //       console.log("Create customer response:", response);
  //     } catch (error) {
  //       console.error("Failed to create customer:", error);
  //     }
  //   }
  //   onClose();
  // };

  // const CreateCustomer = async (values) => {
  //   try {
  //     const response = await axiosInstance.post(URLS.CREATE_CUSTOMER,
  //       {
  //         mobile: values.mobile,
  //         name: values?.name,
  //         email: "",
  //         user_id: user_id
  //       });
  //     console.log("create data", response?.data);
  //     if (response?.data?.result?.status_code == 200) {
  //       setPartnerId(response?.data.result.partner_id);
  //       let customer= {
  //         partnerId:partnerId,
  //         userName:userName,
  //         mobile:mobile,
  //       }
  //       confirm(customer)
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  const CreateCustomer = async (values) => {
    try {
      const response = await axiosInstance.post(URLS.CREATE_CUSTOMER, {
        mobile: values.mobile,
        name: values?.name,
        email: "",
        user_id: user_id,
      });

      // console.log("create data", response?.data);

      if (response?.data?.result?.status_code === 200) {
        setPartnerId(response?.data.result.partner_id);

        let customer = {
          partnerId: response?.data.result.partner_id,
          userName: values?.name,
          mobile: values?.mobile?.replace(/^0+/, ""),
        };
          // console.log("CUSTOMER>>>>>>>>>>>",customer);
          
        confirm(customer);
      }
    } catch (err) {
      console.error(err);
    }
  };
  // to update the existing customer details
  const UpdateCustomer = async (values) => {
    try {
      const response = await axiosInstance.post(URLS.UPDATE_CUSTOMER_DATA, {
        mobile: values.mobile?.replace(/^0+/, ""),
        name: values.name,
        email: "",
        user_id: user_id,
      });
      // console.log("update data", data);
      if (response?.data) {
        let customer = {
          partnerId: partnerId,
          userName: userName,
          mobile: mobile,
        };
        confirm(customer);
      }
    } catch (err) {
      console.error(err);
    }
  };
  // const handleConfirm = async () => {
  //   if (mobile != "") {
  //     let values = {
  //       mobile: mobile,
  //       name: displayName,
  //     };
  //     if (customerExist == true) {
  //       UpdateCustomer(values);
  //     } else {
  //       CreateCustomer(values);
  //     }
  //   } else {
  //     confirm({});
  //   }
  // };

  const handleConfirm = async () => {
    let values = { mobile, name: displayName };

    // Reset error states
    setHasError({ mobile: false, displayName: false });

    // Validate inputs
    if (!mobile) {
      setHasError({
        mobile: !mobile,
        // displayName: !displayName,
      });
      return; // Prevent closing the modal
    }

    // if (!displayName) {
    //   setHasError({
    //     // mobile: !mobile,
    //     displayName: !displayName,
    //   });
    //   return; // Prevent closing the modal
    // }

    if (customerExist) {
      await UpdateCustomer(values);
    } else {
      await CreateCustomer(values);
    }
  };

  const handleCustomerMobile = async (value) => {
    if (value.length >= 10) {
      let payloads = {
        user_id: user_id,
        mobile: value?.replace(/^0+/, ""),
      };

      const response = await axiosInstance.post(
        URLS.GET_CUSTOMER_DETAILS,
        payloads
      );
      if (response?.data?.result?.status == "Customer does not Found") {
        setCutomerExist(false);
        // console.log("nhi mila");
        setDisplayName("");
        setName("");
        setPartnerId("");
      } else {
        setMobile(response?.data?.result.partners_details?.phone);
        setName(response?.data.result.partners_details.name);
        setDisplayName(response?.data.result.partners_details.name);
        setCutomerExist(true);
        setPartnerId(response?.data.result.partners_details.id);
      }
    }
  };

  const handleSkip = () => {
    confirm({ skip: true });
    onClose();
  };

  const handleKeyDown = (e) => {
    const normalizedKey = {
      // "ش": "Enter", // Arabic "ش" is similar to English "Enter"
      "إ": "Shift", // Arabic "إ" is similar to English "Shift"
      "Esc": "Escape", // Arabic "Esc" is similar to English "Escape"
    }[e.key] || e.key; // Fallback to the original key if no mapping exists
  
    if (e.key === "Enter") {
      e.preventDefault();
      // setHasError({displayName: false });
      if (!displayName) {
        setHasError({...hasError, displayName: true });
      } else {
        setHasError({...hasError, displayName: false });
        handleConfirm();
      }
    } else if (e.shiftKey && showSkipButton && normalizedKey === "Shift") {
      e.preventDefault(); // Ensure that the Shift key combination is handled
      handleSkip();
    } else if (normalizedKey === "Escape") {
      e.preventDefault();
      onClose();
    }
  };
  
  useEffect(() => {
    inputRef.current?.focus();
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [displayName, hasError, showSkipButton, handleConfirm, handleSkip, onClose]);

  // const handleKeyDown = (e) => {
  //   if (e.key === "Enter") {
  //     e.preventDefault(); 
  //     // setHasError({displayName: false });
  //     if (!displayName) {
  //       setHasError({...hasError, displayName: true });
  //     } else {
  //       setHasError({...hasError, displayName: false });
  //       handleConfirm();
  //     }
  //   } else if (e.key === "Shift" && showSkipButton) {
  //       handleSkip();
  //   } else if (e.key === "Escape"){
  //     onClose();
  //   }
  // };
  
  // useEffect(() => {
  //   inputRef.current?.focus();
  //   window.addEventListener("keydown", handleKeyDown);
  //   return () => window.removeEventListener("keydown", handleKeyDown);
  // }, []);
  
  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 backdrop-blur-md drop-shadow-sm">
        <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"></div>
        <section
          className="relative flex flex-col px-12 py-12 text-lg font-semibold leading-6 bg-white rounded-[25px] text-zinc-800 max-md:px-5 z-40"
          style={{ width: "600px" }}
        >
          <header className="flex gap-5 text-3xl max-md:flex-wrap max-md:max-w-full">
            <h1 className="flex-auto my-auto">{t("Add Customer")}</h1>
            <button
              type="button"
              onClick={onClose}
              className="text-zinc-800 absolute top-5 right-5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </header>
          <main>
            <InputField
              ref={inputRef}
              autoFocus={true}
              type={"number"}
              inputType={"number"}
              // error={hasError.mobile}
              label={t("Enter phone number")}
              // placeholder="10XXXXXXXX"
              value={mobile}
              changed={(e) => {
                if (e.target.value === "") {
                  setMobile("");
                  setName("");
                }
                const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                let maxLength = 10;

                if (/^0/.test(inputValue)) {
                  maxLength = 11;
                }
                if (inputValue.length < 10) {
                  setDisplayName("");
                  setName("");
                }

                if (
                  /^\d*$/.test(inputValue) &&
                  inputValue.length <= maxLength
                ) {
                  setMobile(inputValue);
                  handleCustomerMobile(inputValue);
                }
                
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleConfirm();
                }
              }}
            />
            <InputField
              label={t("Enter Name")}
              inputType={"text"}
              // placeholder="T"
              // error={hasError.displayName}
              value={displayName}
              readOnly={
                (mobile != "" && userName != "") || mobile?.length < 10
                  ? true
                  : false
              }
              changed={(e) => {
                const char = e.target.value;
                if (/^[a-zA-Z\u0600-\u06FF\s]*$/.test(char)) {
                  // Added \s to allow spaces
                  setDisplayName(char);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleConfirm();
                }
              }}
              maxLength={30}
            />
          </main>
          <footer className="flex justify-between gap-5 items-center">
            {showSkipButton && (
              <button
                onClick={handleSkip}
                className="justify-center items-center px-16 py-3.5 mt-12 text-xl text-center text-[#7EC242] rounded-lg border-2 border-[#7EC242] border-solid capitalize whitespace-nowrap bg-white rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full"
                style={{ width: "500px" }}
              >
                {t("Skip")}
              </button>
            )}
            <button
              disabled={mobile.length === 0 || mobile.length !== len}
              onClick={handleConfirm}
              className="justify-center items-center px-16 py-3.5 mt-12 text-xl text-center text-white capitalize whitespace-nowrap bg-[#7EC242] rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full"
              style={{
                width: "500px",
                opacity: mobile.length > 0  && mobile.length === len? 1 : 0.5,
              }}
            >
              {t("Confirm")}
            </button>
          </footer>
        </section>
      </div>
    </>
  );
}

export default AddCustomer_Modal;
