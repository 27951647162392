// src/redux/draftOrderActions.js

export const ADD_DRAFT_ORDER = 'ADD_DRAFT_ORDER';
export const REMOVE_DRAFT_ORDER = 'REMOVE_DRAFT_ORDER';
export const SELECT_DRAFT_ORDER = 'SELECT_DRAFT_ORDER';
export const CLEAR_DRAFT_ORDERS = 'CLEAR_DRAFT_ORDERS';

export const addDraftOrder = (order) => ({
  type: ADD_DRAFT_ORDER,
  payload: order,
});

export const removeDraftOrder = (orderId) => ({
  type: REMOVE_DRAFT_ORDER,
  payload: orderId,
});

export const selectDraftOrder = (orderId) => ({
  type: SELECT_DRAFT_ORDER,
  payload: orderId,
});

export const clearDraftOrders = () => ({
  type: CLEAR_DRAFT_ORDERS,
});
