import React, { useEffect } from "react";
import checked from "../../assets/Cancel.svg";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";


const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 45,
    backdropFilter: "blur(5px)",
  },
  container: {
    width: "500px",
    padding: "20px",
    borderRadius: "18px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 49,
  },
  title: {
    fontSize: "1.2rem",
    fontWeight: "600",
    textAlign: "center",
    marginBottom: "10px",
    marginTop: "0.5rem",
  },
  image: {
    width: "70px",
    display: "block",
    margin: "20px auto",
    marginBottom: "20px",
  },
  description: {
    textAlign: "center",
    fontWeight: "500",
    fontSize: "1rem",
    color: "black",
    marginBottom: "30px",
  },
};

const ConfirmLanguageChange = ({onClose, confirm, selectedLanguage}) => {
  const {t,i18n}=useTranslation()
 

  const handleGoToOrders = () => {


  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        confirm(); // Trigger Yes
      } else if (e.key === "Escape") {
        onClose(); // Trigger No
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [confirm, onClose]);

  return (
    <div>
      <div style={styles.overlay} ></div>
      <div style={styles.container}>
        {/* <img
          src={checked} // Provide the path to your checked image
          alt="Checked"
          style={styles.image}
        /> */}
        <p style={styles.title}>{t("Confirm Change Language")}</p>
        {/* <p style={styles.title}>{t("Do you want to change your language?")}</p> */}

        <p style={styles.description}>
        {/* {t("On changing language, your cart and draft orders will be cleared! Do you want to change your language to {{language}}?", { language: selectedLanguage === 'en' ? 'English' : 'Arabic' })} */}
        {t("On Changing language, your cart and draft orders will be cleared! Do you want to change your language?")}
        </p>
        <div className="flex justify-around gap-5 mt-7 px-6 mb-2 ">
        <button
            type="button"
            className="w-[150px] h-[45px] bg-white border border-[#7EC242] text-[#7EC242] rounded-lg font-semibold"
             onClick={onClose} // Call handleGoToDashboard onClick
          >
          {t('No')}
          </button>
          {/* <Link to="/login"> */}
          <button
            type="button"
            className="w-[150px] h-[45px] bg-[#7EC242] text-white rounded-lg font-semibold"
             onClick={confirm} // Call handleGoToDashboard onClick
          >
          {t('Yes')}
          </button>
          
        </div>
      </div>
    </div>
  );
};

export default ConfirmLanguageChange;
