const normalState = {
    Productdata:[]
    };
    
   const getcategorydataReducer = (state = normalState, action) => {
      switch (action.type) {
        case 'SEARCH_CATEGORY_DATA':
          return { ...state, Productdata:action.payload  };
       
        default:
          return state;
      }
    };
    export default getcategorydataReducer
    