import "./App.css";
import ProjectRoutes from "./Routes";
import { Bounce, ToastContainer } from 'react-toastify';
import "./global.css"
import "./index.css"  
import 'react-toastify/dist/ReactToastify.css';

const App=()=> {
  return (
    <div className="App">
      {/* <header className="App-header"> */}
        <ProjectRoutes />
        <ToastContainer autoClose={2000} theme="colored" 
        position="top-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        // transition={Bounce}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
    </div>
  );
}

export default App;
