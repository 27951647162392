export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';

export const addProducts = (products) => ({
  type: ADD_PRODUCTS,
  payload: products,
});

export const clearProducts = () => ({
  type: CLEAR_PRODUCTS,
});
