import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ShortcutsModal = ({ isOpen, onClose }) => {
  const { t, i18n } = useTranslation();
  const dir = i18n.dir();

  const shortcuts = [
    {
      key: t("Shift + C"),
      description: t("Add Customer"),
      screen: t(t("Session")),
    },
    {
      key: t("Up Arrow Key / Down Arrow Key"),
      description: t("Select product in cart"),
      screen: t("Session"),
    },
    {
      key: t("+"),
      description: t("Increase quantity of selected product"),
      screen: t("Session"),
    },
    {
      key: t("-"),
      description: t("Decrease quantity of selected product"),
      screen: t("Session"),
    },
    {
      key: t("Alt"),
      description: t("Edit quantity of selected product"),
      screen: t("Session"),
    },
    {
      key: t("Delete"),
      description: t("Remove selected product from cart"),
      screen: t("Session"),
    },
    {
      key: t("Shift + Backspace"),
      description: t("Clear Cart"),
      screen: t("Session"),
    },
    {
      key: t("Shift + N"),
      description: t("New Order (Draft Order)"),
      screen: t("Session"),
    },
    {
      key: t("Shift + S"),
      description: t("Search Products"),
      screen: t("Session"),
    },
    {
      key: t("Shift + Right Arrow Key"),
      description: t("Select Categories"),
      screen: t("Session"),
    },
    {
      key: t("Shift + Down Arrow Key"),
      description: t("Select Products"),
      screen: t("Session"),
    },
    {
      key: t("Space Key"),
      description: t("Add Selected product to cart"),
      screen: t("Session"),
    },
    {
      key: t("Ctrl + Enter"),
      description: t("Proceed to pay"),
      screen: t("Session"),
    },
    {
      key: t("Shift + Enter"),
      description: t("Quick Checkout"),
      screen: t("Session"),
    },
    {
      key: t("Enter"),
      description: t("Proceed to checkout"),
      screen: t("Payment Screen"),
    },
    {
      key: t("Shift"),
      description: t("Select payment method"),
      screen: t("Payment Screen"),
    },
    {
      key: t("Ctrl + X"),
      description: t("Clear amount"),
      screen: t("Payment Screen"),
    },
    {
      key: t("Shift"),
      description: t("Finish Order"),
      screen: t("Order Checkout"),
    },
    {
      key: t("Space Key"),
      description: t("New Order"),
      screen: t("Order Checkout"),
    },
    {
      key: t("Up Arrow Key / Down Arrow Key"),
      description: t("Select Orders"),
      screen: t("Orders"),
    },
    {
      key: t("Enter"),
      description: t("View Order Details"),
      screen: t("Orders"),
    },
    {
      key: t("Esc"),
      description: t("Close Order Details"),
      screen: t("Orders"),
    },
    {
      key: t("Shift + R"),
      description: t("Resend Receipt"),
      screen: t("Orders"),
    },
    {
      key: t("Shift + P"),
      description: t("Print Receipt"),
      screen: t("Orders"),
    },
    { key: t("Shift + A"), description: t("All Orders"), screen: t("Orders") },
    {
      key: t("Shift + Z"),
      description: t("Draft Orders"),
      screen: t("Orders"),
    },
    {
      key: t("Ctrl + Enter"),
      description: t("Continue selling draft order"),
      screen: t("Draft Order"),
    },
    {
      key: t("Shift + D"),
      description: t("Navigate to Dashboard"),
      screen: t("Navbar"),
    },
    {
      key: t("Shift + S"),
      description: t("Navigate to Session"),
      screen: t("Navbar"),
    },
    {
      key: t("Shift + O"),
      description: t("Navigate to Orders"),
      screen: t("Navbar"),
    },
    {
      key: t("Shift + E"),
      description: t("Close Session"),
      screen: t("Navbar"),
    },
    {
      key: t("Shift + J"),
      description: t("Cash In"),
      screen: t("Dashboard / Session"),
    },
    {
      key: t("Shift + K"),
      description: t("Cash Out"),
      screen: t("Dashboard / Session"),
    },
    {
      key: t("Shift"),
      description: t("Settle amount by cash out"),
      screen: t("Close Session"),
    },
    {
      key: t("Up Arrow Key"),
      description: t("Cancel settle amount by cash out"),
      screen: t("Close Session"),
    },
    {
      key: t("Shift + H"),
      description: t("View Shortcuts"),
      screen: t("General"),
    },
    {
      key: t("Shift + L"),
      description: t("Change Language"),
      screen: t("General"),
    },
    { key: t("Shift"), description: t("Skip"), screen: t("General") },
    { key: t("Enter"), description: t("Confirm / Yes"), screen: t("General") },
    { key: t("Esc"), description: t("Close / No"), screen: t("General") },
  ];

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscape);
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const groupedShortcuts = shortcuts.reduce((acc, shortcut) => {
    if (!acc[shortcut.screen]) {
      acc[shortcut.screen] = [];
    }
    acc[shortcut.screen].push(shortcut);
    return acc;
  }, {});

  // const groupedShortcuts = shortcuts.reduce((acc, shortcut) => {
  //   const screen = t(`screens.${shortcut.screen}`);
  //   if (!acc[screen]) {
  //     acc[screen] = [];
  //   }
  //   acc[screen].push({
  //     key: shortcut.key,
  //     description: t(`descriptions.${shortcut.description}`)
  //   });
  //   return acc;
  // }, {});

  return (
    <div className="fixed-modal backdrop-blur-md drop-shadow-sm">
      <div className="bg-white rounded-[25px] h-[90%] w-[70%] shadow-lg p-4 backdrop-blur-md drop-shadow-sm">
        <div className="flex justify-between mb-4">
          <h2 className="text-2xl mt-5 ps-5 font-bold">
            {t("Keyboard Shortcuts")}
          </h2>
          <button
            className="text-gray-600 hover:text-gray-900 transition duration-300"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="overflow-y-auto max-h-[90%] ps-5 custom-scrollbar">
          {Object.entries(groupedShortcuts).map(([screen, shortcuts]) => (
            <div key={screen} className="mb-6">
              <h3 className="text-xl font-semibold mb-2">{screen}</h3>
              <div className="overflow-x-auto">
                <table className="w-full min-w-[600px]">
                  <thead>
                    <tr
                      className={`sticky top-0 bg-gray-100 text-${
                        dir === "rtl" ? "right" : "left"
                      }`}
                    >
                      <th
                        className={`px-4 py-2 w-1/2 text-${
                          dir === "rtl" ? "right" : "left"
                        }`}
                      >
                        {t("Keys")}
                      </th>
                      <th
                        className={`px-4 py-2 w-1/2 text-${
                          dir === "rtl" ? "right" : "left"
                        }`}
                      >
                        {t("Description")}
                      </th>
                    </tr>
                  </thead>
                  {/* <thead>
                    <tr className="sticky top-0 bg-gray-100">
                      <th className="px-4 py-2 text-left w-1/2">{t("Keys")}</th>
                      <th className="px-4 py-2 text-left w-1/2">
                        {t("Description")}
                      </th>
                    </tr>
                  </thead> */}
                  <tbody>
                    {shortcuts.map((shortcut, index) => (
                      <tr key={index}>
                        <td className="px-4 py-2">{shortcut.key}</td>
                        <td className="px-4 py-2">{shortcut.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShortcutsModal;
