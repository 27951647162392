import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import { store } from "../store";

export const getStoreOrdersRequest = () => ({
  type: "GET_STORE_ORDERS_REQUEST",
});

export const getStoreOrdersSuccess = (orders) => ({
  type: "GET_STORE_ORDERS_SUCCESS",
  payload: orders,
});

export const getStoreOrdersFailure = (error) => ({
  type: "GET_STORE_ORDERS_FAILURE",
  payload: error,
});

export const fetchStoreOrders = (pagination, searchTerm, order_filter) => async (dispatch) => {
  // console.log("PPPPPPPPP", order_filter);
  
  const { login } = store.getState();
  // const urlParams = new URLSearchParams(window.location.search);
  // const isRefundOrder = urlParams.get("refundorder") !== null;
  const forRefund = store.getState().refundReducer.forRefund;  // Get from Redux


  dispatch(getStoreOrdersRequest());

  try {
    const payload = {
      offset: pagination,
      limit: 10,
      order_ref: searchTerm,
      order_type: "",
      order_filter: order_filter || "",
    };

   
    if (forRefund) {
      payload.company_id = login?.user?.result?.company_id;
    } else {
      payload.user_id = login?.user?.result?.user_id;
    }

    const response = await axiosInstance.post(URLS.POS_ORDERS, payload);
    // console.log("orders response", response)
    if (response?.data.result.records) {
      dispatch(getStoreOrdersSuccess(response?.data?.result?.records));
    } else {
      dispatch(getStoreOrdersFailure("Failed to fetch orders"));
    }
  } catch (error) {
    dispatch(getStoreOrdersFailure(error.message));
  }
};