import { useSelector } from "react-redux";
import { URLS } from "../../API/API endpoints";
import axiosInstance from "../../AxiosInstance";
import { store } from "../store";


export const sessionidentifier = (val) => ({
  type: "PREVIOUS_SESSION_SUCCESS",
  payload: val,
});
//calling function
export const sessionstatesave = (val) => (dispatch) => {
  // console.log("called");
  dispatch(sessionidentifier(val));
};

export const categorywisedata = (val) => ({
  type: "SEARCH_CATEGORY_DATA",
  payload: val,
});
//calling function
export const getcategorydata = (user_id, search_term) => async (dispatch) => {
  // console.log("called");

  try {
    const payload = {
      product_name: search_term,
      user_id: user_id,
    }
    const response = await axiosInstance.post(URLS.SEARCH_PRODUCT, payload)
    // console.log("called", response);
    if (response?.data?.status_code === 200) dispatch(categorywisedata(response?.data?.result));
  } catch (error) {
    console.error(error)
  }
  return;
};
