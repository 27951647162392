import { useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import { Image } from "antd";
import {
  arrow_down,
  drop_arrow_black,
  drop_arrow_white,
  lang_icon_black,
  lang_icon_white,
} from "../../assets";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
// import Language from "../assets/lang-switcher-icon.svg";
// import arrow from "../assets/Arrow.svg";

export default function LanguageSwitcher({ handleLangChange, theme }) {
  const { t, i18n } = useTranslation(); // Initialize i18n hooks
  const languageMenu = [
    { label: "العربية", value: "ar" },
    { label: "English", value: "en" },
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const [darkMode, setDarkMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // Start with null or an initial value if needed

  // console.log("kabngggg--->", selectedItem);
  useEffect(() => {
    let item;
    if (i18n.language === "ar") {
      item = {
        label: "العربية",
        value: "ar",
      };
    } else {
      item = {
        label: "English",
        value: "en",
      };
    }
    setSelectedItem(item);
  }, []);

  //   const handleItemClick = async (option) => {
  //  if(option.value != i18n.language){
  //     await axiosInstance.post(URLS.CHANGE_LANGUAGE, {
  //      code: option.value,
  //    });
  //    i18n.changeLanguage(option.value); // Change language using i18next
  //    localStorage.setItem("selectedLanguage", option.value); // Save selected language to localStorage
  //    setTimeout(() => {
  //      window.location.reload();
  //    }, 1000);
  //  }

  // };
  return (
    <div className={""}>
      <div className=" lg:ms-0 px-3 font-thin h-12 w-[155px] bg-gradient-to-b  bg-transparent  text-white rounded-xl">
        <Listbox value={selectedItem} onChange={handleLangChange}>
          {({ open }) => (
            <>
              <Listbox.Button className="relative flex h-full w-full cursor-pointer rounded-xl items-center text-[10px] font-medium  py-2 text-sm ">
                <img
                  loading="lazy"
                  src={theme === "dark" ? lang_icon_white : lang_icon_black}
                  alt="Lang Icon"
                  className="shrink-0 my-auto me-1 w-8 h-8 aspect-square"
                />
                <span className={`mx-4}`}>
                  <span
                    className={`relative top-[2px] block text-lg font-medium ${
                      theme === "dark" ? "text-white" : "text-black"
                    }`}
                  >
                    {selectedItem && t(selectedItem.label)}
                  </span>
                </span>
                {/* <span className="hidden items-center truncate xl:flex">
                  <span className="text-md ltr:mr-3 rtl:ml-3">
                    {selectedItem && selectedItem.icon}
                  </span>{" "}
                  {selectedItem && t(selectedItem.label)}
                </span> */}

                <span className="ms-2">
                  <img
                    src={theme === "dark" ? drop_arrow_white : drop_arrow_black}
                    alt="arrow"
                  />
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                // as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Listbox.Options
                  static
                  className="absolute z-40 mt-1 max-h-60 w-[145px] overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  {languageMenu.map((option, index) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        `${active ? "bg-gray-100 text-black" : "text-gray-900"}
                          relative cursor-pointer select-none py-2 px-3`
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <span className="flex items-center text-md">
                          <span className="">{option.icon}</span>
                          <span
                            className={`${
                              selected ? " font-semibold" : "font-semibold"
                            } block truncate ${
                              selectedItem?.value === "ar"
                                ? "rtl:mr-1.5"
                                : "ltr:ml-1.5"
                            }`}
                          >
                            {t(option.label)}
                          </span>
                          {selected && (
                            <span
                              className={`${
                                active && "text-amber-600"
                              } absolute inset-y-0 flex items-center ${
                                selectedItem?.value === "ar"
                                  ? "rtl:right-0 rtl:pr-3"
                                  : "ltr:left-0 ltr:pl-3"
                              }`}
                            />
                          )}
                        </span>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </>
          )}
        </Listbox>
      </div>
    </div>
  );
}

// LanguageSwitcher.js

// import React from 'react';
// import { useTranslation } from 'react-i18next';

// const LanguageSwitcher = () => {
//   const { i18n } = useTranslation();

//   const changeLanguage = async () => {
//     try {
//     //   setLoading(true);
//       i18n.changeLanguage(i18n.language === 'ar' ? 'en' : 'ar');
//       // console.log('i18n.languages', i18n.language);
//     //   saveData();
//     //   I18nManager.forceRTL(i18n.language === 'ar');
//     //   setTimeout(() => {
//     //     RNRestart.restart();
//     //   }, 2000);
//     } catch (err) {
//        console.log('Something went wrong while changing language:', err);
//     }
//   };

//   return (
//     <div>
//       <button onClick={() => changeLanguage('en')}>English</button>
//       <button onClick={() => changeLanguage('ar')}>Arabic</button>
//       {/* Add more language buttons as needed */}
//     </div>
//   );
// };

// export default LanguageSwitcher;
