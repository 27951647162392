// import { useTranslation } from 'react-i18next';

function OrderReceiptPDF(data, t, lang , userData) {
  // const { t, i18n } = useTranslation();


  let htmlProductLines = "";
  // let totalValue = products?.reduce((totalQty, item) => totalQty + (item?.price_unit * (item?.count || item?.product_uom_qty)), 0) + data?.order_details?.shipping_charges + (data?.order_details?.offer ? data?.order_details?.offervalue : 0)
          // <td style="text-align: left;">${item?.name}</td>
          // <td style="text-align: end;">${item?.sub_total}</td>


  if (data?.order[0]?.products_lines) {
    data?.order[0]?.products_lines.forEach((item, index) => {
      htmlProductLines += `
        <tr key=${index}>
          <td style="text-align: center; vertical-align: text-top;">${index + 1
        }</td>
          <td style="text-align: ${lang === 'ar' ? 'right' : 'left'};">${item?.name}</td>
          <td style="text-align: end;">${item?.qty}</td>
          <td style="text-align: center;">${item?.price_unit}</td>
          <td style="text-align: end;">${Number(item.sub_total).toFixed(2)}</td>
        </tr>
      `;
    });
  }

  // console.log("orderNO",data?.order[0]?.order_no)
  let order_id = data?.order[0]?.order_no;
  let order_idChange = order_id?.replace(/\s/g, "%20");
  let Change = 0;
  let Cash;

  data?.order[0]?.payment_data.forEach((item) => {
    if (item?.name == "return") {
      Change = item?.amount;
    }
    if (item?.payment_method == "Bank") {
      Change = 0;
      Cash = item?.amount;
    }
    if (item?.name == false && item?.payment_method == "Cash") {
      Cash = item?.amount;
      Change = (Cash - data?.order[0]?.order_value).toFixed(2);
      // Change = (Cash - receiptData.order[0].order_value).toFixed(2);
    }
  });

  const htmlContent = `
<!DOCTYPE html>
<html>
<head>
  <style>
  body {
    font-family: Arial, sans-serif;
     width:"100%";
    color: #000000;
    font-size: 10px;
  }
 
  .pos-receipt {
    text-align: center;
   
  }
 
  // .pos-receipt-contact div{
  //   font-size:12 px;
   margin-top: 5px;
  // }
  .pos-receipt-contact .cashier {
    // margin-top: 10px;
    // margin-bottom: 10px;
    border-top: 1px dashed black;
  }

  // .pos-receipt-contact .cashier div {
  //   margin-top: 5px;
  //   margin-bottom: 5px;
  // }

  .pos-receipt-contact table {
    width: 100%;
    margin-bottom: 5px;
  }

  .pos-receipt-contact table td:first-child {
    width: 100%;
  }

  .receipt-orderdata {
    width: 100%;
    font-size:10 px;
    margin-bottom: 5px;
    margin-top:5px
  }
  
  .receipt-orderdata th,
  .receipt-orderdata td {
    padding: 0px;
  }
  .receipt-orderdata td {
   font-size: 10px;
  }
  .receipt-orderdata td {
    text-align: center;
    // font-weight: semi-bold;
   
  }

  .pos-receipt-right-align {
    float: right;
  }
  .pos-receipt .pos-receipt-right-align {
    // float: right;
    font-size:10 px;
  }
  .orderDetail{
    display: flex;
    flex-direction:  $ {i18n.language === 'ar' ? 'row-reverse' : 'row'};
    justify-content: space-between;

   
  }
  .orderDetail div, .cashierDetail{
    font-size:10px;
  }
    .totaldash{
     border-top: 1px dashed black; 
}
  .total{
    //  border-top: 1px dashed black;  
     display: flex;
     flex-direction: row;
    justify-content: space-between;
    padding-top: 5px;
    margin-top: 5px;
  }
  .total div, .cashierDetail{
    font-size:9px;
  }
  .lines{
    // margin-top:5px;
    // margin-bottom:5px;

  }
    .order-delivery{
     margin: 10px 0px;
    }
  .receiptHead{
    text-align: center;
  font-weight: semi-bold;
  // padding: 5px 0px 5px;
  font-size: 14px;
  margin: 10px 0px;
  width:100%
  }
  
  .pos-receipt-bottomSection
  {
    border-top: 1px dashed black;
    margin-top:10px;
    font-size:14px;
   
  }
  .bottomSection img{
    width:100px;
  }
  .after-footer{
    margin-top:0px;
  }

   body.arabic {
      direction: rtl;
      text-align: right;
    }
    
    body.arabic .pos-receipt-contact {
      text-align: center;
    }
    body.arabic .receipt-orderdata {
      direction: rtl;
    }
    body.arabic .receipt-orderdata th, body.arabic .receipt-orderdata td {
      text-align: right;
    }
</style>
  </head>
<body ${lang === 'ar' ? 'class="arabic"' : ''}>
  <div class="pos-receipt-container">
    <div class="pos-receipt">
      <div style="text-align: center;">
      <img style="max-height: 50px; max-width: 40px;" src="${data?.order[0]?.compan_add.logo}" alt="My Barcode Image" width="100">
      </div>
      <div class="pos-receipt-contact">
        <div class="lines" >${lang == 'ar' ? userData?.company_name_ar : userData?.company_name}</div>
        <div class="lines">${`${data?.order[0]?.compan_add?.street && data?.order[0]?.compan_add?.street + ", "}
                            ${data?.order[0]?.compan_add?.street2 && data?.order[0]?.compan_add?.street2 + ", "}
                            ${data?.order[0]?.compan_add?.city && data?.order[0]?.compan_add?.city + ", "}
                            ${data?.order[0]?.compan_add?.state && data?.order[0]?.compan_add?.state + ", "}
                            ${data?.order[0]?.compan_add?.country && data?.order[0]?.compan_add?.country}`}</div>
        <div class="lines">${`+20 ${data?.order[0]?.compan_add.tel}`}</div>
      </div>
      <div class="receiptHead">
        <strong >${t("Receipt")}</strong>
      </div>
     
      <div style="text-align: $ {i18n.language === 'ar' ? 'row-reverse' : 'row'};border-top: 1px dashed black;
      border-bottom: 1px dashed black; padding:5px 0px ;">
       

        <div class="orderDetail">
        <div class="lines">
         <strong>${t("Order Number:")}</strong>
        </div>
        <div class="lines">
        ${data?.order[0]?.order_no}
        </div>
       </div>

       <div class="orderDetail">
       <div class="lines">
       <strong>${t("Order Date:")}</strong>
       </div>
       <div class="lines">
      
       ${data?.order[0]?.date}
       </div>
      </div>

        <div class="orderDetail">
      <div class="lines">
      <strong>${t("Cashier Name:")}</strong>
      </div>
      <div class="lines">
      ${data?.order[0]?.cashier}
      </div>
     </div>

     ${data?.order[0]?.customer !== false
      ? `<div class="orderDetail">
          <div class="lines">
            <strong>${t("Customer Name:")}</strong>
          </div>
          <div class="lines">
            ${isNaN(parseFloat(data?.order[0]?.customer)) && 
             !/^\d{3}[-.]?\d{3}[-.]?\d{4}$/.test(data?.order[0]?.customer)
              ? data?.order[0]?.customer
              : "--"}
          </div>
        </div>`
      : ""
    }
    
    ${data?.order[0]?.customer_mobile !== false
      ? `<div class="orderDetail">
          <div class="lines">
            <strong>${t("Customer Phone No.:")}</strong>
          </div>
          <div class="lines">
            ${data?.order[0]?.customer_mobile}
          </div>
        </div>`
      : ""
    }

    

      </div>
     
      <div>
        <table class="receipt-orderdata">
          <colgroup>
             <col width="10%">
              <col width="40%">
              <col width="10%">
              <col width="10%">
              <col width="10%">
          </colgroup>
          <tr >
           <th>${t("S.N")}.</th>
            <td style="text-align: center; font-weight: semi-bold; margin-top: 5px"><strong>${t("Product Name")}</strong></td>
            <td style="text-align: end; font-weight: semi-bold; margin-top: 5px"><strong>${t("Qty")}</strong></td>
            <td style="text-align: end; font-weight: semi-bold; margin-top: 5px"><strong>${t("Unit Price")}</strong></td>
            <td style="text-align: end; font-weight: semi-bold; margin-top: 5px "><strong>${t("Amount")}</strong></td>
          </tr>
          ${htmlProductLines}
        </table>
      <div class="total totaldash">
        <div><strong>${t("Total")}</strong></div>
        <div><strong>EGP ${Number(data?.order[0]?.order_value).toFixed(2)}</strong></div>
      </div>
       <div class="total">
        <div><strong>${t(
      data?.order[0]?.payment_data[0]?.payment_method
    )}</strong></div>
        <div><strong>EGP ${Number(Cash).toFixed(2)}</strong></div>
      </div>
       <div class="total">
        <div><strong>${t("Change")}</strong></div>
         <div><strong>EGP ${Number(Change).toFixed(2)}</strong></div>
      </div>
       <br>
        <div class="after-footer"></div>
        ${order_id ?
      `<div style="display:inline; margin:0px 0px;">
          <img
            src="https://barcode.tec-it.com/barcode.ashx?data=${order_id}&translate-esc=on"
            alt="Receipt Barcode" width="80%" heigth="200"/>
        </div>` : ''
    }
        
    <div class="pos-receipt-bottomSection"><br>
   ${t("Your Technology Partner")}<br>
      <img style="max-height: 50px; max-width: 100px;" src="https://live-production-asset.s3.me-central-1.amazonaws.com/myginne.webp" alt="myGinne Logo">
</div>
  </div>
</body>
</html>
`;
  return htmlContent;
};

export default OrderReceiptPDF;


