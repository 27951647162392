// import TokenInvalidModal from './TokenInvalidModal'; // Import the modal component
import { store } from "./Redux/store";
import axios from "axios";
import React from 'react';
import { createRoot } from 'react-dom/client';
import Sessionexpired from "./Components/Modal/Sessionexpired";


axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

// Add interceptors to add header
axiosInstance.interceptors.request.use(function (config) {
  const { login } = store.getState();
  const auth = "";
  // const lang = auth?.lang;
  // console.log("LLL", lang);
  
  config.headers["access-token"] =  login?.user?.result?.access_token;
  // config.headers["lang"] =  "ar_001";

  return config;
});

// Create a function to render the modal
const showModal = () => {
  const modalContainer = document.createElement('div');
  document.body.appendChild(modalContainer);

  const root = createRoot(modalContainer);
  root.render(<Sessionexpired  />);
};

// Add interceptors to instance
axiosInstance.interceptors.response.use(
  (response) => {
    const errorMessage = response?.data?.result?.message;
    const errorResult = response?.data?.result;
    if (typeof errorResult === 'string' && errorResult.includes('401 UNAUTHORIZED') || errorMessage === "Invalid Token") {
      showModal();
      return response;
    }else{
    return response;
    }
  },
  (error) => {
    if (error?.response?.data?.result?.message === "token not valid") {
      showModal();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
